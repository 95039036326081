import { ReactNode, useCallback, useEffect, useState } from "react";

import { AvatarData, UserPrefs } from "@sportsball/shared";

import { LazyFirebase, useFirebasePreUser } from "./useFirebasePreUser";
import { UserContext } from "./useUser";
import { takeoverAnonUser } from "@/cloudFunctions";
import { useBaseUser, type BaseUser } from "./hooks/useBaseUser";
import { useUserPrefs } from "./hooks/useUserPrefs";
import { usePromotion, type TakeoverCode } from "./hooks/usePromotion";

export interface User extends BaseUser {
  promotedUid?: string;
  prefs?: UserPrefs;
}

async function maybeTakeover(
  firebase: LazyFirebase,
  takeoverCode: Omit<TakeoverCode, "andDelete"> | undefined,
  avatar: AvatarData,
  { andDelete }: { andDelete: boolean }
) {
  if (!takeoverCode) {
    return;
  }
  console.info("takeoverAnonUser", takeoverCode, avatar, andDelete);
  await takeoverAnonUser(firebase, { ...takeoverCode, avatar, andDelete }).catch(console.error);
}

export default function UserProvider({ children }: { children: ReactNode }) {
  const firebase = useFirebasePreUser();
  const baseUser = useBaseUser(firebase);
  const { promotedUid, selfTakeoverCode } = usePromotion(firebase, baseUser);
  const [otherTakeoverCode, setOtherTakeoverCode] = useState<Omit<TakeoverCode, "andDelete"> | undefined>();

  // userDocsUid is the uid of the user id that is used to load user prefs
  let userDocsUid: string | undefined;
  if (baseUser) {
    if (!baseUser.firebaseUserIsAnonymous) {
      userDocsUid = baseUser.firebaseUid;
    } else if (promotedUid) {
      userDocsUid = promotedUid;
    }
  }

  const userPrefs = useUserPrefs(firebase, userDocsUid);

  // once we have a user we can flush out the takeover(s)
  useEffect(() => {
    if (!firebase || !userPrefs) {
      return;
    }
    const { avatar } = userPrefs;
    if (!selfTakeoverCode && !otherTakeoverCode) {
      return; // Skip if no takeover codes present
    }
    maybeTakeover(firebase, selfTakeoverCode, avatar, { andDelete: true })
      .then(() => maybeTakeover(firebase, otherTakeoverCode, avatar, { andDelete: false }))
      .then(() => {
        console.info("takeover effect complete");
        setOtherTakeoverCode(undefined);
      })
      .catch(console.error);
  }, [firebase, userPrefs, selfTakeoverCode, otherTakeoverCode]);

  // handle webview pages with incoming custom tokens
  useEffect(() => {
    if (!firebase) {
      return;
    }

    const url = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (url.startsWith("/webview") && token) {
      const { signInWithCustomToken } = firebase.authPackage;
      console.log("Signing in with token", token);
      // Sign in with the token received from mobile app
      signInWithCustomToken(firebase.auth, token).catch(alert);
    }
  }, [firebase]);

  const user =
    baseUser && promotedUid !== null && userPrefs !== null ? { ...baseUser, promotedUid, prefs: userPrefs } : undefined;

  // signOut: if promoted then we drop the promotion otherwise we just sign in anonymously
  const signOut = useCallback(async () => {
    if (!firebase || !baseUser) {
      return;
    }
    console.info("signOut");
    console.info("promotedUid", promotedUid);
    if (promotedUid) {
      const { collection, doc, deleteDoc } = firebase.firestorePackage;
      console.info("deleting promotion");
      await deleteDoc(doc(collection(firebase.firestore, "anon-promotions"), baseUser.firebaseUid));
    } else {
      console.info("signing in anonymously");
      await firebase.authPackage.signInAnonymously(firebase.auth);
    }
  }, [firebase, baseUser, promotedUid]);

  const setTakeoverCode = ({ anonUid, takeoverCode }: { anonUid: string; takeoverCode: string }) =>
    setOtherTakeoverCode({ anonUid, takeoverCode });

  return (
    <UserContext.Provider
      value={
        firebase && user
          ? { firebase, user, setTakeoverCode, signOut }
          : { firebase: undefined, user: undefined, setTakeoverCode, signOut }
      }
    >
      {children}
    </UserContext.Provider>
  );
}

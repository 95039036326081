import { useContext } from "react";
import { createContext } from "react";

import { LazyFirebase } from "./useFirebasePreUser";
import { User } from "./UserProvider";

export function userUid(user: User): string {
  return user.promotedUid || user.firebaseUid;
}

export function maybeUserUid(user: User | undefined): string | undefined {
  if (!user) {
    return;
  }
  return userUid(user);
}

export function userIsSignedIn(user: User | undefined) {
  if (!user) {
    return;
  }
  return !user.firebaseUserIsAnonymous || user.promotedUid !== undefined;
}

// if firebase is undefined, user will be undefined.
type UserContextType =
  | {
      firebase: undefined;
      user: undefined;
      setTakeoverCode: ({ anonUid, takeoverCode }: { anonUid: string; takeoverCode: string }) => void;
      signOut: () => Promise<void>;
    }
  | {
      firebase: LazyFirebase;
      user: User;
      setTakeoverCode: ({ anonUid, takeoverCode }: { anonUid: string; takeoverCode: string }) => void;
      signOut: () => Promise<void>;
    };

export const UserContext = createContext<UserContextType | undefined>(undefined);

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
}

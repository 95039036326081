import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

import { CreatePickemGameParameters, CreateSquaresGameParameters, getGamePath } from "@sportsball/shared";

import { userIsSignedIn, useUser } from "@/context/useUser";
import SignInDialog from "@/components/SignInDialog";
import FullSizeCard from "@/components/FullSizeCard";

import { createGame, wakeUpFunctions } from "@/cloudFunctions";
import { z } from "zod";

export type PartialCreateGameParameters =
  | Omit<CreatePickemGameParameters, "id" | "name" | "description">
  | Omit<CreateSquaresGameParameters, "id" | "name" | "description">;

export type GetCreateGameData = () => PartialCreateGameParameters;

const _IncomingCreateGameMessage = z.object({
  action: z.literal("createGame"),
});
type _IncomingCreateGameMessage = z.infer<typeof _IncomingCreateGameMessage>;

const _OutgoingCreateGameSuccessMessage = z.object({
  status: z.literal("success"),
  action: z.literal("gameCreated"),
  gameId: z.string(),
});
type _OutgoingCreateGameSuccessMessage = z.infer<typeof _OutgoingCreateGameSuccessMessage>;

const _OutgoingCreateGameErrorMessage = z.object({
  status: z.literal("error"),
  message: z.string(),
});
type _OutgoingCreateGameErrorMessage = z.infer<typeof _OutgoingCreateGameErrorMessage>;

const _OutgoingCreateGameMessage = z.discriminatedUnion("status", [
  _OutgoingCreateGameSuccessMessage,
  _OutgoingCreateGameErrorMessage,
]);
type _OutgoingCreateGameMessage = z.infer<typeof _OutgoingCreateGameMessage>;

export default function NewGame({
  newGameStrings,
  children,
  getCreateGameData,
}: {
  newGameStrings: {
    description: string;
    longName: string;
  };
  children: ReactNode;
  getCreateGameData?: GetCreateGameData;
}) {
  const navigate = useNavigate();
  const { firebase, user } = useUser();
  const [signInDialogOpen, setSignInDialogOpen] = useState(false);

  const { description: gameTypeDescription, longName } = newGameStrings;

  const [gameName, setGameName] = useState<string | undefined>();
  const [gameDescription, setGameDescription] = useState<string>("");
  const [isCreating, setIsCreating] = useState(false);
  const [calledWakeUpFunctions, setCalledWakeUpFunctions] = useState(false);

  useEffect(() => {
    if (!firebase || calledWakeUpFunctions) {
      return;
    }
    wakeUpFunctions(firebase);
    // anticipating the createGame call we warm up the bottleneck function
    setCalledWakeUpFunctions(true);
  }, [firebase, calledWakeUpFunctions]);

  useEffect(() => {
    window.receiveNewGameMessageImpl = (message: _IncomingCreateGameMessage) => {
      if (message.action === "createGame") {
        const submitButton = document.querySelector('button[type="submit"]');
        if (submitButton) {
          (submitButton as HTMLButtonElement).click();
        }
        return;
      }
    };

    return () => {
      window.receiveNewGameMessageImpl = undefined;
    };
  }, []);

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    if (!user || !firebase || !getCreateGameData) {
      _sendNewGameMessage({
        status: "error",
        message: "Not ready to create game",
      });
      throw new Error("Cannot submit form");
    }
    event.preventDefault();
    setIsCreating(true);

    const partialCreateGameParameters = getCreateGameData();
    const createGameParameters = {
      name: gameName ?? longName,
      description: gameDescription,
      ...partialCreateGameParameters,
    };

    createGame(firebase, createGameParameters)
      .then((gameId) => {
        _sendNewGameMessage({
          status: "success",
          action: "gameCreated",
          gameId: gameId,
        });
        navigate(getGamePath({ id: gameId, type: createGameParameters.type }));
      })
      .catch((e) => {
        console.error(e);
        _sendNewGameMessage({
          status: "error",
          message: e instanceof Error ? e.message : String(e),
        });
        alert(e);
        setIsCreating(false);
      });
  }

  const isSignedIn = user && userIsSignedIn(user);
  return (
    <>
      <Helmet>
        <title>{`Create a new ${longName} pool | Sportsball`}</title>
        <meta
          name="description"
          content={`Sportsball is a place to play NFL and NCAA pick'em and squares pools with friends online. Here you can quickly start your own ${longName} pool and invite your friends to play.`}
        />
      </Helmet>
      <form onSubmit={handleSubmit}>
        <FullSizeCard>
          <h1>{`Create a new ${longName} pool`}</h1>
          <p>{gameTypeDescription}</p>
          <p>Name your game.</p>
          <TextField
            id="game-name"
            label="Game Name"
            variant="outlined"
            value={gameName ?? longName}
            onChange={(e) => setGameName(e.target.value)}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setGameName("");
                      document.getElementById("game-name")?.focus();
                    }}
                    edge="end"
                  >
                    <ClearIcon sx={{ backgroundColor: "#e0e0e0", borderRadius: "50%", padding: "4px" }} />
                  </IconButton>
                ),
              },
            }}
            disabled={isCreating}
          />
          <p>Include a description of special rules for your game.</p>
          <TextField
            multiline
            rows={2}
            fullWidth={true}
            id="game-description"
            label="Game Description"
            variant="outlined"
            onChange={(e) => setGameDescription(e.target.value)}
            disabled={isCreating}
          />
        </FullSizeCard>

        {children}

        <FullSizeCard>
          <Stack direction="row" justifyContent="center" spacing={2}>
            {!isSignedIn && (
              <Button variant="contained" onClick={() => setSignInDialogOpen(true)} disabled={isCreating}>
                Sign In to Create Game
              </Button>
            )}
            <Button
              type="submit"
              variant={getCreateGameData ? "contained" : "outlined"}
              disabled={!getCreateGameData || !isSignedIn || isCreating}
            >
              {isCreating ? "Creating..." : "Create Game"}
            </Button>
          </Stack>
        </FullSizeCard>
      </form>
      {firebase && <SignInDialog open={signInDialogOpen} closeDialog={() => setSignInDialogOpen(false)} />}
    </>
  );
}

function _sendNewGameMessage(message: _OutgoingCreateGameMessage) {
  if (!window.Flutter) {
    return;
  }
  window.Flutter.sendNewGameMessage(JSON.stringify(message));
}

window.receiveNewGameMessage = (messageJson: string) => {
  if (!window.receiveNewGameMessageImpl) {
    return;
  }
  try {
    const message = _IncomingCreateGameMessage.parse(messageJson);
    window.receiveNewGameMessageImpl?.(message);
  } catch (error) {
    alert(`React: Error processing message from Flutter: ${String(error)}`);
  }
};

declare global {
  interface Window {
    Flutter?: {
      sendNewGameMessage(message: string): void;
    };
    receiveNewGameMessage: (messageJson: string) => void;
    receiveNewGameMessageImpl?: (message: _IncomingCreateGameMessage) => void;
  }
}

import { ReactNode, useEffect, useState } from "react";

import { Specials } from "@sportsball/shared";

import { getDatabaseUrl } from "@/firebaseConfig";
import { SpecialsContext } from "./useSpecials";

export default function SpecialsProvider({ children }: { children: ReactNode }) {
  const [specials, setSpecials] = useState<Specials | undefined>();

  useEffect(() => {
    const url = `${getDatabaseUrl()}/specials.json`;

    async function fetchSpecials() {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Encoding": "gzip, deflate",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = (await response.json()) as unknown;
      if (!data) {
        return;
      }
      const validatedSpecials = Specials.parse(data);
      setSpecials(validatedSpecials);
    }

    fetchSpecials().catch(alert);
  }, []);

  return <SpecialsContext.Provider value={specials}>{children}</SpecialsContext.Provider>;
}

import CircularProgress from "@mui/material/CircularProgress";

import {
  isStartedPickemGame,
  PickemGameData,
  BasicEntryData,
  pickemGameLockedEntryEntries,
  sortEntries,
} from "@sportsball/shared";

import EntryLine from "@/components/EntryLine";

export default function QuestionsGame({
  gameId,
  game,
  gameOn,
  pregameEntries,
}: {
  gameId: string;
  game: PickemGameData;
  gameOn: boolean;
  pregameEntries?: Partial<Record<string, BasicEntryData>>;
}) {
  if (!pregameEntries && !isStartedPickemGame(game)) {
    return <CircularProgress />;
  }

  if (!isStartedPickemGame(game)) {
    return (
      <>
        {Object.entries(pregameEntries!).map(([entryId, entry]) => (
          <EntryLine
            key={entryId}
            entryId={entryId}
            entry={entry!}
            gameId={gameId}
            gameOn={gameOn}
            avatar={game.avatars?.[entry!.uid]}
          />
        ))}
      </>
    );
  }

  const lockedEntries = pickemGameLockedEntryEntries(game);
  const leaderboard = sortEntries(lockedEntries);

  return (
    <>
      {leaderboard.map(([entryId, entry]) => (
        <EntryLine
          key={entryId}
          entryId={entryId}
          entry={entry}
          gameId={gameId}
          gameOn={gameOn}
          avatar={game.avatars?.[entry.uid]}
        />
      ))}
    </>
  );
}

import { useEffect, useState } from "react";

import deepEqual from "deep-equal";
import { z } from "zod";

import { FootballLeague, LeagueWeek } from "@sportsball/shared";

import { useFirebasePreUser } from "@/context/useFirebasePreUser";

export function useLeagueWeeks() {
  const firebase = useFirebasePreUser();

  const [allLeagueWeeks, setAllLeagueWeeks] = useState<Partial<Record<FootballLeague, LeagueWeek[]>> | undefined>();
  const [tick, setTick] = useState(Date.now());
  const [currentLeagueWeeks, setCurrentLeagueWeeks] = useState<
    Partial<Record<FootballLeague, LeagueWeek>> | undefined
  >();

  useEffect(() => {
    if (!firebase) {
      return;
    }
    const { onValue, ref } = firebase.databasePackage;
    const leagueWeeksRef = ref(firebase.database, "league-weeks");
    return onValue(leagueWeeksRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const newLeagueWeeks = z.record(FootballLeague, z.array(LeagueWeek)).parse(snapshot.val());
      setAllLeagueWeeks(newLeagueWeeks);
    });
  }, [firebase]);

  useEffect(() => {
    const interval = setInterval(() => setTick(Date.now()), 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!allLeagueWeeks) {
      return;
    }
    const newCurrentLeagueWeeks: Partial<Record<FootballLeague, LeagueWeek>> = {};
    for (const [league, leagueWeeks] of Object.entries(allLeagueWeeks)) {
      // skip all the league weeks where our current time is later than the start time of the last game
      const thisLeagueWeek = leagueWeeks.find((leagueWeek) => {
        const lastGame = leagueWeek.games[leagueWeek.games.length - 1];
        return lastGame && lastGame.startTimestamp > tick;
      });
      if (thisLeagueWeek) {
        const currentLeagueWeek = {
          week: thisLeagueWeek.week,
          games: thisLeagueWeek.games.filter((game) => game.startTimestamp > tick),
        };
        newCurrentLeagueWeeks[league as FootballLeague] = currentLeagueWeek;
      }
    }
    if (!deepEqual(newCurrentLeagueWeeks, currentLeagueWeeks)) {
      setCurrentLeagueWeeks(newCurrentLeagueWeeks);
    }
  }, [allLeagueWeeks, tick, currentLeagueWeeks, setCurrentLeagueWeeks]);

  return currentLeagueWeeks;
}

import { useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getGamePath, SomeGameData } from "@sportsball/shared";

import { CopyToClipboardButton } from "@/components/CopyToClipboardButton";

export function makeGameUrl(gameId: string, game: SomeGameData) {
  const { hostname, port, protocol } = window.location;
  const gamePath = getGamePath(game);

  return `${protocol}//${hostname}${port ? `:${port}` : ""}${gamePath}`;
}

export default function ShareDialog({
  open,
  gameId,
  game,
  closeDialog,
}: {
  open: boolean;
  gameId: string;
  game: SomeGameData;
  closeDialog: () => void;
}) {
  const gameUrl = makeGameUrl(gameId, game);
  const shareUrl = `${gameUrl}/share`;

  // because the share-game link is hosted on an on-demand platform we pre-fetch here
  // so when the link is received the service might be warm.
  useEffect(() => {
    if (open) {
      fetch(shareUrl).catch(console.error);
    }
  }, [open, shareUrl]);

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Share Game</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sharing {game.type} game named {game.name}
        </DialogContentText>
        <Stack padding={2} spacing={2} alignItems="center">
          <QRCodeSVG value={gameUrl} />,
          <CopyToClipboardButton label="🔗 Copy Invite Link" buffer={shareUrl} />
          <Stack direction="row" justifyContent="center" spacing={0.5}>
            <Typography display="inline">Game code:</Typography>
            <Typography display="inline" fontWeight="bold" sx={{ userSelect: "text" }}>
              {game.code.toUpperCase()}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

import { useEffect, useState } from "react";

import deepEqual from "deep-equal";
import { z } from "zod";

import { ScheduledBaseballGame } from "@sportsball/shared";
import { useFirebasePreUser } from "@/context/useFirebasePreUser";

export function useBaseballDay() {
  const firebase = useFirebasePreUser();

  const [allBaseballDays, setAllBaseballDays] = useState<Record<string, ScheduledBaseballGame[]> | undefined>();

  const [tick, setTick] = useState(Date.now());
  const [currentBaseballDay, setCurrentBaseballDay] = useState<ScheduledBaseballGame[] | undefined>();

  useEffect(() => {
    if (!firebase) {
      return;
    }
    const { onValue, ref } = firebase.databasePackage;
    const baseballGamesRef = ref(firebase.database, "games/baseball/MLB");
    return onValue(baseballGamesRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const newBaseballDays = z.record(z.string(), z.array(ScheduledBaseballGame)).parse(snapshot.val());
      setAllBaseballDays(newBaseballDays);
    });
  }, [firebase]);

  useEffect(() => {
    const interval = setInterval(() => setTick(Date.now()), 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!allBaseballDays) {
      return;
    }
    // skip all the league weeks where our current time is later than the start time of the last game
    const newCurrentBaseballDay = Object.values(allBaseballDays).find((games) => {
      const lastGame = games[games.length - 1];
      return lastGame && lastGame.startTimestamp > tick;
    });
    if (!deepEqual(newCurrentBaseballDay, currentBaseballDay)) {
      setCurrentBaseballDay(newCurrentBaseballDay);
    }
  }, [allBaseballDays, tick, currentBaseballDay, setCurrentBaseballDay]);

  return currentBaseballDay;
}

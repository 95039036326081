import { useOutletContext } from "react-router-dom";
import { GameContainerProps } from "@/components/GameContainer";
import QuestionsGame from "./QuestionsGame";
import SquaresGame from "./squares/SquaresGame";

export default function WebViewGame() {
  const { type, game, gameId, gameOn, pregameEntries } = useOutletContext<GameContainerProps>();

  switch (type) {
    case "Pickem":
      return <QuestionsGame gameId={gameId} game={game} gameOn={gameOn} pregameEntries={pregameEntries} />;

    case "Squares":
      return <SquaresGame gameId={gameId} game={game} pregameEntries={pregameEntries} />;
  }
}

import { useLocation, useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function LeftRightButtons({ paths, color }: { paths: string[]; color?: string }) {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  if (paths.length < 2) {
    return null;
  }
  let leftNavPath: string | undefined;
  let rightNavPath: string | undefined;
  const pathIndex = paths.findIndex((path) => path === location);
  if (pathIndex !== -1) {
    leftNavPath = paths[(pathIndex + paths.length - 1) % paths.length];
    rightNavPath = paths[(pathIndex + 1) % paths.length];
  }

  return (
    <>
      {leftNavPath && (
        <IconButton onClick={() => navigate(leftNavPath, { replace: true })}>
          <ArrowBackIcon sx={{ color }} />
        </IconButton>
      )}
      {rightNavPath && (
        <IconButton onClick={() => navigate(rightNavPath, { replace: true })}>
          <ArrowForwardIcon sx={{ color }} />
        </IconButton>
      )}
    </>
  );
}

import { useState, useEffect } from "react";
import { AnonPromotion } from "@sportsball/shared";
import { LazyFirebase } from "../useFirebasePreUser";
import { BaseUser } from "./useBaseUser";
import { generateTakeoverCode } from "@/cloudFunctions";

export interface TakeoverCode {
  anonUid: string;
  takeoverCode: string;
}

export function usePromotion(firebase: LazyFirebase | undefined, baseUser: BaseUser | undefined) {
  const [promotedUid, setPromotedUid] = useState<string | null | undefined>(null);
  const [selfTakeoverCode, setSelfTakeoverCode] = useState<Omit<TakeoverCode, "andDelete"> | undefined>();

  // Watch promotion status for anonymous users
  useEffect(() => {
    if (!baseUser || !firebase || !baseUser.firebaseUserIsAnonymous) {
      setPromotedUid(undefined);
      return;
    }

    const { firebaseUid } = baseUser;
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    console.info("watching anonPromotion doc", firebaseUid);
    const anonPromotionDoc = doc(collection(firebase.firestore, "anon-promotions"), firebaseUid);

    return onSnapshot(anonPromotionDoc, (snapshot) => {
      if (snapshot.exists()) {
        const { promotedToUid } = AnonPromotion.parse(snapshot.data());
        console.info("found anon promotion - user is signed in as", promotedToUid);
        setPromotedUid(promotedToUid);
        setSelfTakeoverCode(undefined);
      } else {
        console.info("no anon promotion - user is signed out", firebaseUid);
        setPromotedUid(undefined);

        // Generate takeover code for signed out users
        console.info("generating takeover code", baseUser.firebaseUid);
        generateTakeoverCode(firebase)
          .then(({ takeoverCode }) => setSelfTakeoverCode({ takeoverCode, anonUid: baseUser.firebaseUid }))
          .catch(console.error);
      }
    });
  }, [baseUser, firebase]);

  return { promotedUid, selfTakeoverCode };
}

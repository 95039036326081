import { useEffect } from "react";

import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { getFilteredGames, getLeagueForTeamSlate, ScheduledFootballGame } from "@sportsball/shared";
import { TeamSlate, TeamSlateShortNames } from "@sportsball/shared";
import deepEqual from "deep-equal";
import { useLeagueWeeks } from "@/hooks/leagueWeeksHook";

export default function SlateGamesPicker({
  title,
  description,
  teamSlate,
  setTeamSlate,
  slateWeekGames,
  setSlateWeekGames,
}: {
  title: string;
  description: string;
  teamSlate: TeamSlate;
  setTeamSlate: (teamSlate: TeamSlate) => void;
  slateWeekGames: ScheduledFootballGame[];
  setSlateWeekGames: (slateWeekGames: ScheduledFootballGame[]) => void;
}) {
  const currentLeagueWeeks = useLeagueWeeks();

  useEffect(() => {
    if (!currentLeagueWeeks) {
      return;
    }
    const league = getLeagueForTeamSlate(teamSlate);
    const newSlateWeekGames = getFilteredGames(currentLeagueWeeks[league]?.games ?? [], teamSlate);
    if (!deepEqual(slateWeekGames, newSlateWeekGames)) {
      setSlateWeekGames(newSlateWeekGames);
    }
  }, [currentLeagueWeeks, teamSlate, slateWeekGames, setSlateWeekGames]);

  return (
    <>
      <h2>{title}</h2>
      <p>{description}</p>
      <Stack direction="row" spacing={2}>
        <TextField
          select
          id="TeamSlate"
          label="League/Conference"
          value={teamSlate ?? ""}
          fullWidth
          SelectProps={{
            MenuProps: {
              style: {
                maxHeight: 400,
              },
            },
          }}
          onChange={(e) => setTeamSlate(e.target.value as TeamSlate)}
        >
          {[...Object.values(TeamSlate)].map((ncaaGameFilter) => {
            const league = getLeagueForTeamSlate(ncaaGameFilter);
            const week = currentLeagueWeeks?.[league]?.week;
            return (
              <MenuItem key={ncaaGameFilter} value={ncaaGameFilter}>
                {TeamSlateShortNames[ncaaGameFilter]}
                {week ? ` ${week}` : ""}
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
    </>
  );
}

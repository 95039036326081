import { ReactNode, useCallback, useEffect, useState } from "react";

import { type User as FirebaseUser } from "@firebase/auth";

import { AnonPromotion, AvatarData, UserPrefs } from "@sportsball/shared";

import { LazyFirebase, useFirebasePreUser } from "./useFirebasePreUser";
import { UserContext } from "./useUser";
import { generateTakeoverCode, takeoverAnonUser } from "@/cloudFunctions";
import deepEqual from "deep-equal";

interface BaseUser {
  firebaseUid: string;
  firebaseUserIsAnonymous: boolean;
}

export interface User extends BaseUser {
  promotedUid?: string;
  prefs?: UserPrefs;
}

export interface TakeoverCode {
  anonUid: string;
  takeoverCode: string;
  andDelete: boolean;
}

function _baseUserFromFirebaseUser(firebaseUser: FirebaseUser | null): User | undefined {
  if (!firebaseUser) {
    return undefined;
  }
  const { uid, isAnonymous } = firebaseUser;
  return { firebaseUid: uid, firebaseUserIsAnonymous: isAnonymous };
}

async function maybeTakeover(
  firebase: LazyFirebase,
  takeoverCode: Omit<TakeoverCode, "andDelete"> | undefined,
  avatar: AvatarData,
  { andDelete }: { andDelete: boolean }
) {
  if (!takeoverCode) {
    return;
  }
  console.info("takeoverAnonUser", takeoverCode, avatar, andDelete);
  await takeoverAnonUser(firebase, { ...takeoverCode, avatar, andDelete }).catch(alert);
}

export default function UserProvider({ children }: { children: ReactNode }) {
  const firebase = useFirebasePreUser();
  // 4 components to a user.
  // {firebaseUid, firebaseUserIsAnonymous} come from the logged in firebase user (baseUser)
  // promotedUid comes from the anon-promotions collection, when firebaseUserIsAnonymous is true (promotedUid)
  //
  // userPrefs come from the users collection, when the user is signed in(userPrefs)
  // promotedUid and userPrefs are null when the state is "unknown". undefined is the known-missing state
  //
  // we have a defined user object when we have baseUser and promotedUid and userPrefs are not null
  //
  // possible states with a defined user object (all have baseUser defined)
  //
  // SignedInUser (native): firebaseUserIsAnonymous is false and userPrefs is defined
  // SignedInUser (via promotion): firebaseUserIsAnonymous is true and promotedUid and userPrefs are defined
  // SignedOutUser: firebaseUserIsAnonymous is true and promotedUid is undefined

  const [baseUser, setBaseUser] = useState<BaseUser | undefined>();
  const [promotedUid, setPromotedUid] = useState<string | null | undefined>(null);
  const [userPrefs, setUserPrefs] = useState<UserPrefs | null | undefined>(null);

  // takeovers happen when an anonymous user
  const [selfTakeoverCode, setSelfTakeoverCode] = useState<Omit<TakeoverCode, "andDelete"> | undefined>();
  const [otherTakeoverCode, setOtherTakeoverCode] = useState<Omit<TakeoverCode, "andDelete"> | undefined>();

  // keep user up to date and kick off anonymous sign-in for very first time in (user === null)
  useEffect(() => {
    if (!firebase) {
      return;
    }
    const { onAuthStateChanged, signInAnonymously } = firebase.authPackage;
    return onAuthStateChanged(firebase.auth, (firebaseUser) => {
      const newBaseUser = _baseUserFromFirebaseUser(firebaseUser);

      // kick off anonymous sign-in for very first time in
      if (!newBaseUser) {
        signInAnonymously(firebase.auth).catch(alert);
      }

      // if there's no change to base user then we can stop right here
      if (deepEqual(newBaseUser, baseUser)) {
        return;
      }

      console.info("onAuthStateChanged updating baseUser", newBaseUser);
      setBaseUser(newBaseUser);

      // if we are anon then we need to wait for firebase to determine if we are promoted
      // otherwise we are signed in and we can set the promotedUid to undefined
      setPromotedUid(newBaseUser?.firebaseUserIsAnonymous ? null : undefined);

      // for user prefs we have to wait for firebase to load them and we can still be logged
      // in as an anon user so we set the userPrefs to null
      setUserPrefs(null);

      // clear out any self takeover code
      setSelfTakeoverCode(undefined);
    });
  }, [firebase, baseUser]);

  // for anonymous users watch promotion and set up takeover code if we end up as signed out
  useEffect(() => {
    if (!baseUser || !firebase || !baseUser.firebaseUserIsAnonymous) {
      return;
    }
    const { firebaseUid } = baseUser;
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    console.info("watching anonPromotion doc", firebaseUid);
    const anonPromotionDoc = doc(collection(firebase.firestore, "anon-promotions"), firebaseUid);
    return onSnapshot(anonPromotionDoc, (snapshot) => {
      if (snapshot.exists()) {
        // set the promoted uid but leave userPrefs null as it will load from the users collection
        const { promotedToUid } = AnonPromotion.parse(snapshot.data());
        console.info("found anon promotion - user is signed in as", promotedToUid);
        setPromotedUid(promotedToUid);
      } else {
        console.info("no anon promotion - user is signed out", firebaseUid);
        // this makes the user signed out user so fill in promotedUid and userPrefs as undefined
        setPromotedUid(undefined);
        setUserPrefs(undefined);

        // once we know we have a signed user we set up their takeover code
        console.info("generating takeover code", baseUser.firebaseUid);
        generateTakeoverCode(firebase)
          .then(({ takeoverCode }) => setSelfTakeoverCode({ takeoverCode, anonUid: baseUser.firebaseUid }))
          .catch(alert);
      }
    });
  }, [baseUser, firebase]);

  // userDocsUid is the uid of the user id that is used to load user prefs
  let userDocsUid: string | undefined;
  if (baseUser) {
    if (!baseUser.firebaseUserIsAnonymous) {
      userDocsUid = baseUser.firebaseUid;
    } else if (promotedUid) {
      userDocsUid = promotedUid;
    }
  }

  // last step for a signed in user is to load/watch their user prefs
  useEffect(() => {
    if (!firebase || !userDocsUid) {
      return;
    }
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    const usersCollection = collection(firebase.firestore, "users");
    const userPrefsDoc = doc(usersCollection, userDocsUid);
    return onSnapshot(userPrefsDoc, (snapshot) => {
      if (snapshot.exists()) {
        const userPrefs = UserPrefs.parse(snapshot.data());
        setUserPrefs(userPrefs);
      } else {
        setUserPrefs(undefined);
      }
    });
  }, [firebase, userDocsUid]);

  // once we have a user we can flush out the takeover(s)
  useEffect(() => {
    if (!firebase || !userPrefs) {
      return;
    }
    const { avatar } = userPrefs;
    maybeTakeover(firebase, selfTakeoverCode, avatar, { andDelete: true })
      .then(() => maybeTakeover(firebase, otherTakeoverCode, avatar, { andDelete: false }))
      .catch(alert);
    setSelfTakeoverCode(undefined);
    setOtherTakeoverCode(undefined);
  }, [firebase, userPrefs, selfTakeoverCode, otherTakeoverCode]);

  const user =
    baseUser && promotedUid !== null && userPrefs !== null ? { ...baseUser, promotedUid, prefs: userPrefs } : undefined;

  // signOut: if promoted then we drop the promotion otherwise we just sign in anonymously
  const signOut = useCallback(async () => {
    if (!firebase || !baseUser) {
      return;
    }
    console.info("signOut");
    console.info("promotedUid", promotedUid);
    if (promotedUid) {
      const { collection, doc, deleteDoc } = firebase.firestorePackage;
      console.info("deleting promotion");
      await deleteDoc(doc(collection(firebase.firestore, "anon-promotions"), baseUser.firebaseUid));
    } else {
      console.info("signing in anonymously");
      await firebase.authPackage.signInAnonymously(firebase.auth);
    }
  }, [firebase, baseUser, promotedUid]);

  const setTakeoverCode = ({ anonUid, takeoverCode }: { anonUid: string; takeoverCode: string }) =>
    setOtherTakeoverCode({ anonUid, takeoverCode });

  return (
    <UserContext.Provider
      value={
        firebase && user
          ? { firebase, user, setTakeoverCode, signOut }
          : { firebase: undefined, user: undefined, setTakeoverCode, signOut }
      }
    >
      {children}
    </UserContext.Provider>
  );
}

import { useBaseballDay } from "@/hooks/baseballDayHook";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { ScheduledBaseballGame } from "@sportsball/shared";
import { useEffect } from "react";

export default function MlbGamePicker({
  selectedGame,
  setSelectedGame,
}: {
  selectedGame?: ScheduledBaseballGame;
  setSelectedGame: (game: ScheduledBaseballGame) => void;
}) {
  const baseballDay = useBaseballDay();

  function setBaseballGameId(id: string) {
    const newGame = baseballDay?.find((game) => game.id === id);
    if (newGame) {
      setSelectedGame(newGame);
    }
  }

  // first time we get data and don't have a selected game, select the first game
  useEffect(() => {
    if (!baseballDay || selectedGame) {
      return;
    }
    setSelectedGame(baseballDay[0]);
  }, [baseballDay, selectedGame, setSelectedGame]);

  return !baseballDay || baseballDay.length === 0 ? null : (
    <TextField
      select
      fullWidth
      id="game"
      label={`MLB Game`}
      value={selectedGame?.id ?? ""}
      SelectProps={{
        MenuProps: {
          style: {
            maxHeight: 400,
          },
        },
      }}
      onChange={(e) => setBaseballGameId(e.target.value)}
    >
      {baseballDay?.map((game) => {
        return (
          <MenuItem key={game.id} value={game.id}>
            {game.teams.away.name} @ {game.teams.home.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export function calculateDogMoneyLineScore({ favoriteOdds, dogOdds }: { favoriteOdds: number; dogOdds: number }) {
  const dogScore = dogOdds / favoriteOdds;

  // progressive rounding. round to nearest .1 if < 10, nearest 1 if < 30, nearest 5 if > 30
  if (dogScore < 10) {
    return Math.round(dogScore * 10) / 10;
  }
  if (dogScore < 30) {
    return Math.round(dogScore);
  }
  return Math.round(dogScore / 5) * 5;
}

export function calculateMoneyLineScoring({ home: homeOdds, away: awayOdds }: { home: number; away: number }) {
  if (awayOdds > homeOdds) {
    return {
      home: 1.0,
      away: calculateDogMoneyLineScore({ favoriteOdds: homeOdds, dogOdds: awayOdds }),
    };
  }
  return {
    away: 1.0,
    home: calculateDogMoneyLineScore({ favoriteOdds: awayOdds, dogOdds: homeOdds }),
  };
}

import { ReactElement } from "react";

import { circularProgress } from "@/main";

export function processError({ loading, error }: { loading: boolean; error: Error | undefined }): ReactElement {
  if (loading) {
    // center loading spinner on the page verically and horizontally
    return circularProgress();
  }
  if (error) {
    alert("Parsing Error");
    return <p>{error.message}</p>;
  }
  return <div />;
}

import { useEffect, useState } from "react";
import { type User as FirebaseUser } from "@firebase/auth";
import { LazyFirebase } from "../useFirebasePreUser";
import deepEqual from "deep-equal";

export interface BaseUser {
  firebaseUid: string;
  firebaseUserIsAnonymous: boolean;
}

function baseUserFromFirebaseUser(firebaseUser: FirebaseUser | null): BaseUser | undefined {
  if (!firebaseUser) {
    return undefined;
  }
  const { uid, isAnonymous } = firebaseUser;
  return { firebaseUid: uid, firebaseUserIsAnonymous: isAnonymous };
}

export function useBaseUser(firebase: LazyFirebase | undefined) {
  const [baseUser, setBaseUser] = useState<BaseUser | undefined>();

  useEffect(() => {
    if (!firebase) {
      return;
    }

    const { onAuthStateChanged, signInAnonymously } = firebase.authPackage;
    return onAuthStateChanged(firebase.auth, (firebaseUser) => {
      const newBaseUser = baseUserFromFirebaseUser(firebaseUser);

      // kick off anonymous sign-in for very first time in
      if (!newBaseUser) {
        signInAnonymously(firebase.auth).catch(console.error);
      }

      // if there's no change to base user then we can stop right here
      if (deepEqual(newBaseUser, baseUser)) {
        return;
      }

      console.info("onAuthStateChanged updating baseUser", newBaseUser);
      setBaseUser(newBaseUser);
    });
  }, [firebase, baseUser]);

  return baseUser;
}

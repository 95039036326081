import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  getCellId,
  isStartedSquaresGame,
  singleSportsGame,
  BasicEntryData,
  SquaresGameData,
  teamLabel,
  getSquaresGameStatus,
} from "@sportsball/shared";

import { InitialsProps, TitleProps } from "@/style";

import SquaresScoreboard from "./SquaresScoreboard";
import { DarkGridGuideColor, LightGridGuideColor } from "./SquaresBoardColors";
import TargetScore from "./TargetScore";

import { maybeUserUid, useUser } from "@/context/useUser";
import deepEqual from "deep-equal";

export default function SquaresBoard({
  game,
  pregameEntries,
  handleClaimSquare,
}: {
  game: SquaresGameData;
  pregameEntries?: Partial<Record<string, BasicEntryData>>;
  handleClaimSquare?: ({ cellEntryId }: { cellEntryId: string }) => void;
}) {
  const { size, targetScores } = game;
  const { user } = useUser();
  const uid = maybeUserUid(user);

  const sportsGame = singleSportsGame(game.sportsGames);
  if (!sportsGame) {
    throw new Error("Squares game without football game");
  }
  const gameLocked = isStartedSquaresGame(game);
  const isEditing = !gameLocked && Boolean(handleClaimSquare);

  const entries = isStartedSquaresGame(game) ? game.lockedEntries : pregameEntries;

  const sizeDimension = size === 25 ? 5 : 10;
  const _dimension = [...Array(sizeDimension).keys()];

  const gameStatus = isStartedSquaresGame(game) ? getSquaresGameStatus(game) : undefined;
  const activeCell = gameStatus?.lastPeriodActive
    ? gameStatus.periodResults[gameStatus.periodResults.length - 1].rowCol
    : undefined;

  function awayScoreCol() {
    return (
      <Grid container columns={1} width="50px">
        <TargetScore
          size={size}
          score={undefined}
          side="Away"
          visibility={"hidden"}
          rowOrCol={0}
          activeCell={undefined}
        />
        {_dimension.map((i) => (
          <TargetScore
            key={i}
            size={size}
            side="Away"
            score={targetScores?.away?.[i]}
            rowOrCol={i}
            activeCell={activeCell}
          />
        ))}
      </Grid>
    );
  }

  function homeScoreRow() {
    return _dimension.map((i) => (
      <TargetScore
        key={i}
        size={size}
        side="Home"
        score={targetScores?.home?.[i]}
        rowOrCol={i}
        activeCell={activeCell}
      />
    ));
  }

  function boardRow(row: number) {
    return _dimension.map((col) => {
      const rowCol = { row, col };
      const cellEntryId = getCellId(rowCol);
      const cellEntry = entries?.[cellEntryId];
      const initials = cellEntry?.entryName ?? "-";
      const cellWinLabels =
        gameStatus?.periodResults.filter((w) => deepEqual(w.rowCol, rowCol)).map((w) => w.label) ?? [];

      const matchesAway = targetScores && activeCell && activeCell.row === row;
      const matchesHome = targetScores && activeCell && activeCell.col === col;
      const activeWinner = matchesAway && matchesHome;
      const isEditable = (!gameLocked && !cellEntry) || cellEntry?.uid === uid;
      const bgcolor = activeWinner
        ? DarkGridGuideColor
        : matchesAway || matchesHome
        ? LightGridGuideColor
        : !isEditable && isEditing
        ? "lightgray"
        : undefined;

      return (
        <Grid
          key={col}
          position="relative"
          size={1}
          paddingY={1}
          textAlign="center"
          onClick={() => {
            if (!isEditable) {
              return;
            }
            handleClaimSquare?.({ cellEntryId });
          }}
          borderBottom={2}
          borderRight={2}
          bgcolor={bgcolor}
        >
          <Typography
            {...InitialsProps}
            fontWeight={activeWinner ? "900" : "400"}
            visibility={cellEntry ? "visible" : "hidden"}
            sx={{ opacity: isEditable || !isEditing ? 1 : 0.5 }}
          >
            <span style={{ whiteSpace: "nowrap" }}>{initials}</span>
          </Typography>
          {cellWinLabels.length > 0 && (
            <Typography position="absolute" fontSize={8} bottom={0} left={2} fontWeight="bold" color="red">
              {cellWinLabels.join(" ")}
            </Typography>
          )}
        </Grid>
      );
    });
  }

  function squaresGrid() {
    function theGrid(columns: number) {
      return (
        <Grid container columns={columns} width="100%">
          {homeScoreRow()}
          {_dimension.map((row) => boardRow(row))}
        </Grid>
      );
    }

    if (size === 100) {
      return (
        <Box overflow="auto" sx={{ touchAction: "auto" }}>
          <Box width={500}>{theGrid(10)}</Box>
        </Box>
      );
    }
    return theGrid(5);
  }

  return (
    <Stack spacing={2}>
      <Box width="100%" padding={isEditing ? 1 : 0}>
        <Stack>
          {!isEditing && (
            <Typography variant="h5" align="center" padding={1} {...TitleProps}>
              {teamLabel(sportsGame.teams, "home")}
            </Typography>
          )}
          <Stack direction="row" justifyContent="center">
            {!isEditing && (
              <Typography
                variant="h5"
                align="center"
                padding={1}
                {...TitleProps}
                sx={{ writingMode: "vertical-lr", transform: "rotate(180deg)", marginLeft: -3 }}
              >
                {teamLabel(sportsGame.teams, "away")}
              </Typography>
            )}
            {awayScoreCol()}
            {squaresGrid()}
          </Stack>
        </Stack>
      </Box>
      {!isEditing && <SquaresScoreboard game={game} gameStatus={gameStatus} />}
    </Stack>
  );
}

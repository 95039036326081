import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import {
  AllFootballSquaresPeriods,
  MLB_TEAM_ABBREVIATIONS,
  PeriodResult,
  singleSportsGame,
  SquaresGameData,
  SquaresGameStatus,
} from "@sportsball/shared";

import { FootballPeriodScore } from "./FootballPeriodScore";
import { LightGridGuideColor } from "./SquaresBoardColors";

export default function SquaresScoreboard({
  game,
  gameStatus,
}: {
  game: SquaresGameData;
  gameStatus?: SquaresGameStatus;
}) {
  const sportsGame = singleSportsGame(game.sportsGames);
  const periodResults: (PeriodResult | undefined)[] = [...(gameStatus?.periodResults ?? [])];
  const activePeriodIndex = gameStatus?.lastPeriodActive ? periodResults.length - 1 : undefined;

  const fullGameSize = sportsGame.type === "Football" ? AllFootballSquaresPeriods.length : 20;
  while (periodResults.length < fullGameSize) {
    periodResults.push(undefined);
  }

  switch (sportsGame.type) {
    case "Football":
      return (
        <Box padding={2}>
          <Grid container columns={4} width="100%">
            {periodResults.map((periodResult, i) => (
              <FootballPeriodScore
                key={i}
                period={AllFootballSquaresPeriods[i]}
                scores={periodResult?.scores}
                isActive={i === activePeriodIndex}
              />
            ))}
          </Grid>
        </Box>
      );
    case "Baseball": {
      const getCellStyle = (periodIndex?: number) => ({
        sx: {
          border: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 1,
          bgcolor: periodIndex !== undefined && periodIndex === activePeriodIndex ? LightGridGuideColor : "inherit",
        },
      });

      return (
        <Box padding={2}>
          <Grid container columns={12} width="100%">
            {/* Header Row */}
            <Grid size={2}></Grid>
            {Array.from({ length: 10 }, (_, i) => (
              <Grid key={i} size={1} {...getCellStyle()}>
                {i < 9 ? i + 1 : "10+"}
              </Grid>
            ))}

            {/* Away Team Row */}
            <Grid size={2} {...getCellStyle()}>
              {MLB_TEAM_ABBREVIATIONS[sportsGame.teams.away.id]}
            </Grid>
            {Array.from({ length: 10 }, (_, i) => (
              <Grid key={i} size={1} {...getCellStyle(i * 2)}>
                {sportsGame.periodScores?.[i * 2] ?? "-"}
              </Grid>
            ))}

            {/* Home Team Row */}
            <Grid size={2} {...getCellStyle()}>
              {MLB_TEAM_ABBREVIATIONS[sportsGame.teams.home.id]}
            </Grid>
            {Array.from({ length: 10 }, (_, i) => (
              <Grid key={i} size={1} {...getCellStyle(i * 2 + 1)}>
                {sportsGame.periodScores?.[i * 2 + 1] ?? "-"}
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }
  }
}

import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { getGamePath, isSquaresGame, singleSportsGame, type SomeGameData } from "@sportsball/shared";

import Countdown from "@/components/Countdown";
import GameTitle from "@/components/GameTitle";
import SquaresScoreboard from "@/components/squares/SquaresScoreboard";

export function GameSummary({ game }: { game: SomeGameData }) {
  if (isSquaresGame(game)) {
    const sportsGame = singleSportsGame(game.sportsGames);
    if (!sportsGame) {
      throw new Error("Squares game without football game");
    }
    return <SquaresScoreboard game={game} />;
  }
  return <div />;
}

function GameCard({ game }: { game: SomeGameData }) {
  const navigate = useNavigate();
  const sportsGame = isSquaresGame(game) ? singleSportsGame(game.sportsGames) : undefined;
  const { startTimestamp } = game;

  return (
    <Card
      sx={{
        position: "relative",
        overflow: "visible",
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-2px)",
        },
      }}
    >
      <Stack
        spacing={2}
        p={3}
        onClick={() => navigate(getGamePath(game))}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            `linear-gradient(to right bottom, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
          color: "white",
        }}
      >
        {/* Game Title */}
        <GameTitle game={game} />

        {/* Teams Section */}
        {sportsGame && (
          <Stack
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              px: 2,
              py: 3,
              bgcolor: "rgba(255, 255, 255, 0.1)",
              borderRadius: 2,
            }}
          >
            {/* Away Team */}
            <Stack direction="column" alignItems="center" flex={1}>
              {"city" in sportsGame.teams.away && (
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  {sportsGame.teams.away.city}
                </Typography>
              )}
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {sportsGame.teams.away.name}
              </Typography>
            </Stack>

            {/* VS Divider */}
            <Stack alignItems="center" sx={{ px: 2 }}>
              <Typography
                variant="h6"
                fontFamily="Timmana"
                sx={{
                  opacity: 0.8,
                  fontWeight: "light",
                }}
              >
                VS
              </Typography>
            </Stack>

            {/* Home Team */}
            <Stack direction="column" alignItems="center" flex={1}>
              {"city" in sportsGame.teams.home && (
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  {sportsGame.teams.home.city}
                </Typography>
              )}
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {sportsGame.teams.home.name}
              </Typography>
            </Stack>
          </Stack>
        )}

        {/* Countdown or Game Summary */}
        <Box
          sx={{
            mt: 1,
            textAlign: "center",
          }}
        >
          {startTimestamp && <Countdown startTimestamp={startTimestamp} startedElement={<GameSummary game={game} />} />}
        </Box>
      </Stack>
    </Card>
  );
}

export default GameCard;

import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { AvatarData, AvatarSetSizes, UserPrefs } from "@sportsball/shared";

import AvatarIcon from "@/avatar/AvatarIcon";
import { userIsSignedIn, userUid, useUser } from "@/context/useUser";

export default function UserPrefsDialog({ open, closeDialog }: { open: boolean; closeDialog: () => void }) {
  const { firebase, user } = useUser();
  const [avatarData, setAvatarData] = useState<AvatarData | undefined>();
  const [screenName, setScreenName] = useState<string | undefined>();
  const [initials, setInitials] = useState<string | undefined>();
  const signedInUid = user && userIsSignedIn(user) ? userUid(user) : undefined;

  useEffect(() => {
    if (!signedInUid || !firebase) {
      return;
    }
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    const usersCollection = collection(firebase.firestore, "users");
    const userPrefsRef = doc(usersCollection, signedInUid);

    return onSnapshot(userPrefsRef, (snapshot) => {
      if (!snapshot.exists()) {
        setAvatarData(undefined);
        setScreenName("");
        setInitials("");
        return;
      }
      const results = UserPrefs.safeParse(snapshot.data());
      if (!results.success) {
        return;
      }

      setAvatarData(results.data.avatar);
      setScreenName(results.data.preferredScreenName);
      setInitials(results.data.preferredInitials);
    });
  }, [signedInUid, firebase]);

  function changeAvatar(amount: number) {
    if (!avatarData) {
      return;
    }
    const { set, id } = avatarData;
    const avatarSetSize = AvatarSetSizes[set] ?? 1;
    setAvatarData({ set, id: (id + amount + avatarSetSize) % avatarSetSize });
  }

  const handleSave = async () => {
    if (!avatarData || !screenName || !initials || !signedInUid || !firebase) {
      console.error("Missing required user preferences");
      // Handle error appropriately - maybe show a toast or error message
      return;
    }

    try {
      const prefs: UserPrefs = {
        avatar: avatarData,
        preferredScreenName: screenName,
        preferredInitials: initials,
      };
      const { collection, doc, setDoc } = firebase.firestorePackage;
      const usersCollection = collection(firebase.firestore, "users");
      await setDoc(doc(usersCollection, signedInUid), prefs);
      closeDialog();
    } catch (error) {
      console.error("Failed to save user preferences:", error);
      // Handle error appropriately
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle variant="h5">Your Settings</DialogTitle>
      <DialogContent>
        <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;&nbsp;Your avatar</Typography>
        <Stack spacing={2} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={() => changeAvatar(-1)}>
              <KeyboardArrowLeftIcon fontSize="large" />
            </IconButton>
            <AvatarIcon avatarData={avatarData} size="large" />
            <IconButton onClick={() => changeAvatar(+1)}>
              <KeyboardArrowRightIcon fontSize="large" />
            </IconButton>
          </Stack>
          <TextField
            label="Preferred Screen Name"
            value={screenName}
            onChange={(e) => setScreenName(e.target.value)}
          ></TextField>
          <TextField
            label="Preferred Initials"
            value={initials}
            onChange={(e) => setInitials(e.target.value)}
          ></TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button onClick={() => void handleSave()}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

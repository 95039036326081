import { FormEvent, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { GoogleIcon } from "./CustomIcons";

import { userIsSignedIn, useUser } from "@/context/useUser";
import { generateTakeoverCode } from "@/cloudFunctions";
import { isFirebaseError } from "@/firebaseConfig";

export default function SignInDialog({
  open,
  closeDialog,
  message,
}: {
  open: boolean;
  closeDialog: () => void;
  message?: string;
}) {
  const { firebase, user } = useUser();
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [busy, setBusy] = useState(false);
  const [showCheckEmailMessage, setShowCheckEmailMessage] = useState(false);

  const isSignedIn = user && userIsSignedIn(user);

  // Add this effect to reset checkEmail when dialog opens
  useEffect(() => {
    if (open) {
      setShowCheckEmailMessage(false);
    }
  }, [open]);

  // watch for sign in and close dialog if sign in happens
  useEffect(() => {
    if (open && isSignedIn) {
      closeDialog();
    }
  }, [open, isSignedIn, closeDialog]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
    });
  };

  function validateInputs(email: string | undefined) {
    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    return isValid;
  }

  async function handleEmailSignIn() {
    if (!firebase) {
      throw new Error("Firebase is not initialized");
    }
    const emailEl = document.getElementById("email") as HTMLInputElement;
    const email = emailEl.value;
    if (!validateInputs(email)) {
      return false;
    }

    try {
      setBusy(true);
      const params = new URLSearchParams();
      const { takeoverCode } = await generateTakeoverCode(firebase);

      params.set("email", email);
      params.set("anonUid", user.firebaseUid);
      params.set("takeoverCode", takeoverCode);
      params.set("thenNavigate", window.location.pathname);

      const { hostname, port, protocol } = window.location;
      const url = `${protocol}//${hostname}${port ? `:${port}` : ""}/finish-email-sign-in?${params.toString()}`;
      const actionCodeSettings = { url, handleCodeInApp: true };
      const { sendSignInLinkToEmail } = firebase.authPackage;

      await sendSignInLinkToEmail(firebase.auth, email, actionCodeSettings);
      setShowCheckEmailMessage(true);
      return true;
    } catch (error) {
      alert(error);
      return false;
    } finally {
      setBusy(false);
    }
  }

  async function handleProviderSignIn() {
    if (!firebase) {
      throw new Error("Firebase is not initialized");
    }

    try {
      setBusy(true);
      const { GoogleAuthProvider, signInWithPopup, signInWithRedirect } = firebase.authPackage;
      const provider = new GoogleAuthProvider();

      const { hostname } = window.location;
      if (hostname === "localhost" || hostname.includes("--pr")) {
        const credentials = await signInWithPopup(firebase.auth, provider);
        console.log(credentials.operationType, credentials.providerId, credentials.user.displayName);
        closeDialog();
      } else {
        await signInWithRedirect(firebase.auth, provider);
      }
    } catch (e) {
      if (isFirebaseError(e, firebase)) {
        alert(`${e.code}: ${e.message}`);
      } else {
        const message = e instanceof Error ? e.message : "Unknown error";
        alert(message);
      }
      closeDialog();
    } finally {
      setBusy(false);
    }
  }

  return (
    <Dialog open={open} onClose={() => closeDialog()}>
      <DialogTitle variant="h5">Sign In</DialogTitle>
      {showCheckEmailMessage && (
        <DialogContent>
          <p>Check your email for a link to use to sign in to Sportsball.</p>
          <p>
            If an email from Sportsball does not show up in a minute, please check your spam folder. If you find the
            email there, please mark is as &quot;not spam&quot; so that future emails will be routed to your inbox.
          </p>
          <Stack direction="row" justifyContent="center">
            <Button
              variant="contained"
              onClick={() => {
                setShowCheckEmailMessage(false);
                closeDialog();
              }}
            >
              Close
            </Button>
          </Stack>
        </DialogContent>
      )}
      {!showCheckEmailMessage && (
        <DialogContent>
          {busy && (
            <Box padding={4}>
              <CircularProgress size={120} />
            </Box>
          )}
          {!busy && (
            <Stack spacing={2}>
              {message && <DialogContentText>{message}</DialogContentText>}
              <DialogContentText>Use social sign in.</DialogContentText>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  onClick={() => void handleProviderSignIn()}
                  startIcon={<GoogleIcon />}
                >
                  Sign in with Google
                </Button>
              </Box>
              <Divider>or</Divider>
              <DialogContentText>Sign in with email link.</DialogContentText>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 2,
                }}
              >
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <TextField
                    error={emailError}
                    helperText={emailErrorMessage}
                    id="email"
                    type="email"
                    name="email"
                    placeholder="your@email.com"
                    autoComplete="email"
                    required
                    fullWidth
                    variant="outlined"
                    color={emailError ? "error" : "primary"}
                    sx={{ ariaLabel: "email" }}
                  />
                </FormControl>
                <Button type="submit" fullWidth variant="contained" onClick={() => void handleEmailSignIn()}>
                  Sign in
                </Button>
              </Box>
            </Stack>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
}

import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CancelIcon from "@mui/icons-material/Cancel";

import {
  isGameOff,
  isPickemGame,
  PickemEntryPrivateData,
  Side,
  getGamePath,
  BasicEntryDataWithType,
} from "@sportsball/shared";

import AvatarIcon from "@/avatar/AvatarIcon";
import FullSizeCard from "@/components/FullSizeCard";
import PickemSheet from "@/components/PickemSheet";
import SignInDialog from "@/components/SignInDialog";
import { GameContainerProps } from "@/components/GameContainer";

import { maybeUserUid, userIsSignedIn, useUser } from "@/context/useUser";

function _dbSafeEntryId(entryName: string) {
  return entryName.toLowerCase().replace(/[^a-zA-Z0-9]/g, "_");
}

export default function JoinPickemGame() {
  const navigate = useNavigate();
  const { firebase, user } = useUser();
  const { type, game, gameId, pregameEntries } = useOutletContext<GameContainerProps>();
  const [entryName, setEntryName] = useState(user?.prefs?.preferredScreenName ?? "");
  const [selectedAnswers, setSelectedAnswers] = useState<Record<string, Side>>({});
  const [tiebreakerPoints, setTiebreakerPoints] = useState<number | undefined>();
  const [showSignIn, setShowSignIn] = useState(false);

  if (!gameId) {
    throw new Error("Missing gameId");
  }
  if (!isPickemGame(game) || type !== "Pickem") {
    throw new Error("Game is not a Pickem game");
  }
  const { questions } = game;
  const slateSize = questions.filter((question) => !isGameOff(question)).length;

  const entryId = _dbSafeEntryId(entryName);
  const isEntryNameValid = entryId.length > 0;
  const isEntryIdTaken = Boolean(pregameEntries?.[entryId]);
  const isReady =
    isEntryNameValid &&
    !isEntryIdTaken &&
    Object.keys(selectedAnswers).length === slateSize &&
    (game.tiebreakerId ? tiebreakerPoints !== undefined : true);

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEntryName(event.target.value.trim());
  }

  function makePick({ questionNumber, pick }: { questionNumber: number; pick: Side }) {
    const newAnswers = { ...selectedAnswers };
    newAnswers[questionNumber.toString()] = pick;
    setSelectedAnswers(newAnswers);
  }

  function randomizeAnswers() {
    const randomAnswers: Record<string, Side> = {};
    for (let questionNumber = 0; questionNumber < questions.length; questionNumber++) {
      const question = questions[questionNumber];
      if (isGameOff(question)) {
        continue;
      }

      randomAnswers[questionNumber.toString()] = Math.random() < 0.5 ? "Away" : "Home";
    }
    setSelectedAnswers(randomAnswers);
  }

  async function handleJoin() {
    if (!isReady) {
      throw new Error("Missing required data");
    }
    const uid = maybeUserUid(user);

    if (!gameId || !firebase || !uid) {
      return;
    }

    try {
      const { doc, writeBatch } = firebase.firestorePackage;
      const avatar = user?.prefs?.avatar;
      const newEntry: BasicEntryDataWithType = {
        gameType: "Pickem",
        uid,
        entryName: entryName,
        ...(avatar && { avatar }),
      };
      const newPrivateEntry: PickemEntryPrivateData = {
        uid,
        selectedAnswers,
        ...(tiebreakerPoints !== undefined && { tiebreakerPoints }),
      };

      const batch = writeBatch(firebase.firestore);
      const pregameEntryRef = doc(firebase.firestore, `/games/${gameId}/pregame-entries/${entryId}`);
      batch.set(pregameEntryRef, newEntry);
      const privateEntryRef = doc(firebase.firestore, `/games/${gameId}/pregame-entries-private/${entryId}`);
      batch.set(privateEntryRef, newPrivateEntry);
      await batch.commit();

      navigate(getGamePath(game));
    } catch (e: unknown) {
      alert("Error submitting entry" + JSON.stringify(e));
    }
  }

  const uid = maybeUserUid(user);
  const otherPlayers = Object.entries(game.avatars ?? {}).filter(([entryUid]) => entryUid !== uid);
  const isSignedIn = user && userIsSignedIn(user);
  const pickemGameType = questions.length > 0 ? questions[0].type : undefined;

  return (
    <>
      <form>
        <FullSizeCard>
          <h1>Join {game.name}</h1>
          {otherPlayers.length > 0 && (
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
              <Typography>Other players:</Typography>
              <AvatarGroup max={10}>
                {otherPlayers.map(([uid, avatar]) => (
                  <AvatarIcon key={uid} avatarData={avatar} size="medium" />
                ))}
              </AvatarGroup>
            </Stack>
          )}
          <p>{game.description}</p>
          {pickemGameType === "MoneyLinePick" && (
            <p>
              This pick&apos;em pool uses Money Line scoring. Choose the teams that you think will win. Every winning
              pick with earn you the points assigned to that team. Winning with a <i>favorite</i> will earn you{" "}
              <span style={{ fontSize: "1.2em" }}>①</span> point. Winning with an <i>underdog</i> will earn you up to{" "}
              <span style={{ fontSize: "1.2em" }}>⑩</span> points depending on the odds. Choose your picks wisely!
            </p>
          )}
          {pickemGameType === "VsSpreadPick" && (
            <p>
              This pick&apos;em pool uses <i>Against the Spread</i> scoring. Underdogs have a + number next to their
              name and that number is the <i>spread</i>. If you pick an underdog you can still win even if that team
              loses as long as they lose by less than the spread. If you pick the favorite, they will have to win by{" "}
              <i>more than</i> the spread for you to be victorious! Every win is worth 1 point.
            </p>
          )}
          {!isSignedIn && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Button fullWidth={false} onClick={() => setShowSignIn(true)}>
                Sign In
              </Button>
              <Typography>For the best experience!</Typography>
            </Stack>
          )}
        </FullSizeCard>
        <FullSizeCard>
          <h2>Enter Your Screen Name</h2>
          <Box>
            <TextField
              id="entryName"
              defaultValue={entryName}
              label="Screen Name"
              variant="outlined"
              onChange={handleNameChange}
              error={!isEntryNameValid || isEntryIdTaken}
              helperText={
                !isEntryNameValid ? "Screen name cannot be empty" : isEntryIdTaken ? "This name is already taken" : " "
              }
            />
          </Box>
        </FullSizeCard>
        <FullSizeCard>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <h2>Make Your Picks</h2>
            <IconButton onClick={randomizeAnswers}>🎲</IconButton>
          </Stack>
          <p>
            You will be able to change your picks until the first game starts at{" "}
            {new Date(game.startTimestamp).toLocaleString(undefined, {
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
            })}
          </p>
          <PickemSheet
            game={game}
            picks={selectedAnswers}
            tiebreakerPoints={tiebreakerPoints}
            makePick={makePick}
            setTiebreakerPoints={setTiebreakerPoints}
          />
        </FullSizeCard>
        <FullSizeCard>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="contained" onClick={() => void handleJoin()} disabled={!isReady}>
              Submit Entry
            </Button>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => navigate(`/games/${gameId}`)}>
              Cancel
            </Button>
          </Stack>
        </FullSizeCard>
      </form>
      <SignInDialog open={showSignIn} closeDialog={() => setShowSignIn(false)} />
    </>
  );
}

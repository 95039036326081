import { ReactNode } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export default function FullSizeCard({ children }: { children: ReactNode }) {
  return (
    <Box width="100%" marginBottom={0.75} marginTop={0.75}>
      <Stack width="100%" spacing={2} sx={{ borderRadius: 2, bgcolor: "white", padding: 2 }}>
        {children}
      </Stack>
    </Box>
  );
}

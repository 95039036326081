import {
  BottleneckFunctionParameters,
  BottleneckFunctionResponse,
  CreateGameParameters,
  CreateGameResponse,
  EntryParameters,
  GameParameters,
  LockSquaresGameParameters,
  TakeoverAnonUserParameters,
  TakeoverCodeResponse,
} from "@sportsball/shared";

import { LazyFirebase } from "@/context/useFirebasePreUser";
import { z } from "zod";

async function _callBottleneckFunction<T>(
  firebase: LazyFirebase,
  params: BottleneckFunctionParameters,
  schema: z.ZodSchema<T>
): Promise<T> {
  const { httpsCallable } = firebase.functionsPackage;
  const callBottleneckFunctionFn = httpsCallable<BottleneckFunctionParameters, BottleneckFunctionResponse>(
    firebase.functions,
    "main-bottleneck"
  );
  const { data } = await callBottleneckFunctionFn(params);
  const result = schema.safeParse(data);
  if (!result.success) {
    console.error("Invalid response from bottleneck function", data);
    throw new Error(`Invalid response from bottleneck function: ${result.error.message}`);
  }
  console.log("Valid response from bottleneck function", result.data);
  return result.data;
}

export function wakeUpFunctions(firebase: LazyFirebase | undefined) {
  if (!firebase) {
    return;
  }
  _callBottleneckFunction(firebase, { bottleneck: "WAKE_UP" }, z.object({})).catch((e) => {
    console.error("Problem waking up functions", e);
  });
}

export async function createGame(firebase: LazyFirebase, createGameData: CreateGameParameters): Promise<string> {
  switch (createGameData.type) {
    case "Pickem":
      return (
        await _callBottleneckFunction(
          firebase,
          { bottleneck: "CreatePickemGame", ...createGameData },
          CreateGameResponse
        )
      ).gameId;
    case "Squares":
      return (
        await _callBottleneckFunction(
          firebase,
          { bottleneck: "CreateSquaresGame", ...createGameData },
          CreateGameResponse
        )
      ).gameId;
    default:
      throw new Error("Invalid game type");
  }
}

export async function lockSquaresGame(firebase: LazyFirebase, params: LockSquaresGameParameters) {
  await _callBottleneckFunction(firebase, { bottleneck: "LockSquaresGame", ...params }, z.object({}));
}

export async function deleteGame(firebase: LazyFirebase, params: GameParameters) {
  await _callBottleneckFunction(firebase, { bottleneck: "DeleteGame", ...params }, z.object({}));
}

export async function deleteEntry(firebase: LazyFirebase, params: EntryParameters) {
  await _callBottleneckFunction(firebase, { bottleneck: "DeleteEntry", ...params }, z.object({}));
}

export async function generateTakeoverCode(firebase: LazyFirebase) {
  return await _callBottleneckFunction(firebase, { bottleneck: "GenerateTakeoverCode" }, TakeoverCodeResponse);
}

export async function takeoverAnonUser(firebase: LazyFirebase, params: TakeoverAnonUserParameters) {
  const { httpsCallable } = firebase.functionsPackage;
  const fn = httpsCallable<TakeoverAnonUserParameters>(firebase.functions, "main-takeoverAnonUser");
  await fn(params);
}

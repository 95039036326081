import { useEffect, useState } from "react";

import { intervalToDuration } from "date-fns";
import Typography from "@mui/material/Typography";
import { TitleProps } from "@/style";

function _format(num: number | undefined) {
  if (!num) {
    return "00";
  }
  return num.toString().padStart(2, "0");
}

export default function Countdown({
  startTimestamp,
  startedElement,
}: {
  startTimestamp: number;
  startedElement?: JSX.Element;
}) {
  const [now, setNow] = useState(Date.now());
  const tick = () => {
    setNow(Date.now());
  };

  useEffect(() => {
    const interval = setInterval(() => tick(), 1000);
    return () => clearInterval(interval);
  }, []);

  if (!startTimestamp || now >= startTimestamp) {
    return startedElement;
  }

  const diff = intervalToDuration({
    start: now,
    end: startTimestamp,
  });

  if (diff.days && diff.days > 0) {
    return (
      <Typography {...TitleProps} variant="h5" textAlign="center">
        {diff.days} {diff.days > 1 ? "days" : "day"} and {diff.hours} {diff.hours! > 1 ? "hours" : "hour"} to gametime
      </Typography>
    );
  }

  return (
    <Typography {...TitleProps} variant="h4" textAlign="center">
      {_format(diff.hours)} : {_format(diff.minutes)} : {_format(diff.seconds)}
    </Typography>
  );
}

import Typography from "@mui/material/Typography";

export default function BonusPointsCoin({ bonusPoints }: { bonusPoints: number }) {
  // Determine textColor, backgroundColor and borderColor based on bonusPoints
  let textColor;
  let backgroundColor;
  let borderColor;

  if (bonusPoints === 1) {
    textColor = "black";
    backgroundColor = "white";
    borderColor = "#666666"; // Dark gray border for basic points
  } else if (bonusPoints < 1.5) {
    textColor = "white";
    backgroundColor = "#B87333"; // Copper for 1-1.5 points
    borderColor = "#8B4513"; // Darker copper border
  } else if (bonusPoints <= 2.5) {
    textColor = "black";
    backgroundColor = "silver"; // Silver for 1.5-2.5 points
    borderColor = "#696969"; // Darker silver border
  } else {
    textColor = "black";
    backgroundColor = "gold"; // Gold for 2.5 and above
    borderColor = "#DAA520"; // Darker gold border
  }

  return (
    <div
      style={{
        backgroundColor,
        borderRadius: "50%",
        width: "25px",
        height: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        border: `2px solid ${borderColor}`,
      }}
    >
      <Typography variant="caption" color={textColor} fontWeight="bold">
        {bonusPoints % 1 === 0 ? bonusPoints.toString() : bonusPoints.toFixed(1)}
      </Typography>
    </div>
  );
}

import { useState, useEffect } from "react";
import { UserPrefs } from "@sportsball/shared";
import { LazyFirebase } from "../useFirebasePreUser";

export function useUserPrefs(firebase: LazyFirebase | undefined, userDocsUid: string | undefined) {
  const [userPrefs, setUserPrefs] = useState<UserPrefs | null | undefined>(null);

  useEffect(() => {
    if (!firebase || !userDocsUid) {
      setUserPrefs(undefined);
      return;
    }

    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    const usersCollection = collection(firebase.firestore, "users");
    const userPrefsDoc = doc(usersCollection, userDocsUid);

    return onSnapshot(userPrefsDoc, (snapshot) => {
      if (snapshot.exists()) {
        const userPrefs = UserPrefs.parse(snapshot.data());
        setUserPrefs(userPrefs);
      } else {
        setUserPrefs(undefined);
      }
    });
  }, [firebase, userDocsUid]);

  return userPrefs;
}

import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Footer from "@/components/Footer";
import GameContainer from "@/components/GameContainer";
import Titlebar from "@/components/Titlebar";
import UserPrefsDialog from "@/components/UserPrefsDialog";

import FinishEmailSignIn from "@/pages/FinishEmailSignIn";
import Game from "@/pages/Game";
import Home from "@/pages/Home";
import JoinPickemGame from "@/pages/JoinPickemGame";
import NewPickemGame from "@/pages/NewPickemGame";
import NewSquaresGame from "@/pages/squares/NewSquaresGame";
import Room from "@/pages/Room";
import ShowEntry from "@/pages/ShowEntry";
import WebViewGame from "@/pages/WebViewGame";

// Component to manage canonical tag
function CanonicalTag() {
  const location = useLocation();
  const [canonicalUrl, setCanonicalUrl] = useState<string | undefined>();

  useEffect(() => {
    const url = `${window.location.origin}${location.pathname}`;
    setCanonicalUrl(url);
  }, [location.pathname]);

  return canonicalUrl ? (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  ) : null;
}

export default function App() {
  const [showUserPrefs, setShowUserPrefs] = useState(false);

  const isInWebView = window.location.pathname.startsWith("/webview");

  return (
    <HelmetProvider>
      <CanonicalTag />
      {!isInWebView && <Titlebar />}
      <Box component="main" width="100%" sx={{ userSelect: "none" }}>
        <Box marginBottom={1}>
          {!isInWebView && <Toolbar />}

          <Routes>
            <Route index element={<Home />} />

            <Route path="/games/new/football-pickem" element={<NewPickemGame />} />
            <Route path="/games/new/football-pickem/slate/:teamSlate" element={<NewPickemGame />} />

            <Route path="/games/new/football-squares" element={<NewSquaresGame sport="Football" />} />
            <Route
              path="/games/new/football-squares/special/:specialId"
              element={<NewSquaresGame sport="Football" />}
            />
            <Route path="/games/new/football-squares/slate/:teamSlate" element={<NewSquaresGame sport="Football" />} />

            <Route path="/games/new/baseball-squares" element={<NewSquaresGame sport="Baseball" />} />

            <Route path="/rooms/:roomId" element={<Room />} />

            <Route path="/webview/games/:gameId" element={<GameContainer key={`webview-${useParams().gameId}`} />}>
              <Route index element={<WebViewGame />} />
            </Route>

            <Route path="/webview/new/football-squares" element={<NewSquaresGame sport="Football" />} />
            <Route path="/webview/new/baseball-squares" element={<NewSquaresGame sport="Baseball" />} />
            <Route path="/webview/new/football-pickem" element={<NewPickemGame />} />

            <Route path="/games/:gameId/*" element={<GameContainer key={`pickem-${useParams().gameId}`} />}>
              <Route index element={<Game />} />
              <Route path="entries/:entryId" element={<ShowEntry />} />
              <Route path="join" element={<JoinPickemGame />} />
            </Route>

            <Route path="/finish-email-sign-in" element={<FinishEmailSignIn />} />

            <Route path="*" element={<Home />} />
          </Routes>

          <UserPrefsDialog open={showUserPrefs} closeDialog={() => setShowUserPrefs(false)} />
        </Box>
        {!isInWebView && <Footer />}
      </Box>
    </HelmetProvider>
  );
}

import { useCallback } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { BasicEntryData, BasicEntryDataWithType, SquaresGameData } from "@sportsball/shared";

import SquaresBoard from "./SquaresBoard";

import { maybeUserUid, useUser } from "@/context/useUser";

export default function SquaresBoardEditor({
  show,
  doneEditing,
  initials,
  setInitials,
  gameId,
  game,
  pregameEntries,
}: {
  show: boolean;
  doneEditing: () => void;
  initials: string;
  setInitials: (initials: string) => void;
  gameId: string;
  game: SquaresGameData;
  pregameEntries?: Partial<Record<string, BasicEntryData>>;
}) {
  const { user, firebase } = useUser();
  const uid = maybeUserUid(user);

  const handleClaimSquare = useCallback(
    async ({ cellEntryId }: { cellEntryId: string }) => {
      if (!firebase || !uid) {
        return;
      }

      try {
        const { deleteDoc, doc, setDoc } = firebase.firestorePackage;
        const pregameEntryRef = doc(firebase.firestore, `games/${gameId}/pregame-entries/${cellEntryId}`);

        if (pregameEntries?.[cellEntryId]?.uid === uid) {
          await deleteDoc(pregameEntryRef);
        } else if (!pregameEntries?.[cellEntryId]) {
          const cellEntryData: BasicEntryDataWithType = {
            gameType: "Squares",
            uid,
            entryName: initials,
          };
          await setDoc(pregameEntryRef, cellEntryData);
        }
      } catch (error) {
        alert(error);
      }
    },
    [firebase, gameId, uid, pregameEntries, initials]
  );

  function someoneElseHasInitials(initials: string) {
    return Object.values(pregameEntries ?? {}).some((entry) => entry?.entryName === initials && entry?.uid !== uid);
  }

  function processInitials(initials: string) {
    const segmenter = new Intl.Segmenter("en", { granularity: "grapheme" });
    const segments = Array.from(segmenter.segment(initials)).map((s) => s.segment);
    return segments.slice(0, 3).join("").toUpperCase();
  }

  return (
    <Dialog open={show} onClose={doneEditing} sx={{ userSelect: "none" }}>
      <DialogTitle>Make your Picks</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText>Choose your initials then tap a square to claim it. Tap again to clear.</DialogContentText>
          <TextField
            label="Initials"
            sx={{ mt: 1 }}
            value={initials}
            onChange={(e) => setInitials(processInitials(e.target.value ?? ""))}
            error={!initials || someoneElseHasInitials(initials)}
            helperText={!initials ? "Initials required" : someoneElseHasInitials(initials) ? "Already taken" : ""}
            required
          />
        </Stack>
      </DialogContent>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises*/}
      <SquaresBoard game={game} pregameEntries={pregameEntries} handleClaimSquare={handleClaimSquare} />
      <DialogActions>
        <Button onClick={doneEditing}>Done</Button>
      </DialogActions>
    </Dialog>
  );
}

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { format } from "date-fns";
import deepEqual from "deep-equal";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  BasicEntryData,
  getCellId,
  ScheduledFootballGame,
  ScheduledGame,
  SquaresGameData,
  StartedSquaresGameData,
  TeamSlateLongNames,
} from "@sportsball/shared";
import { TeamSlate } from "@sportsball/shared";

import NewGame from "../NewGame";
import { generateTargetScores } from "./SquaresGame";

import FullSizeCard from "@/components/FullSizeCard";
import SlateGamesPicker from "@/components/SlateGamesPicker";
import SquaresBoard from "@/components/squares/SquaresBoard";

import { useSpecials } from "@/context/useSpecials";
import MlbGamePicker from "./MlbGamePicker";

const gameTypeStrings = {
  Football: {
    description:
      "Squares pools are a fun way to compete against your friends to see who can pick the correct scores for each quarter of an NFL or NCAA football game. Choose your game and board size below and have fun!",
    slug: "squares",
  },
  Baseball: {
    description:
      "Squares pools are a fun way to compete against your friends to see who can pick the correct scores for each inning of an MLB game. Choose your game and board size below and have fun!",
    slug: "squares",
  },
};
export type NewSquaresGameData = Omit<SquaresGameData, "id" | "status" | "uid" | "code" | "name" | "description">;

function _generateInitials() {
  // random name initials created from all upper case characters except Q, X, Y, Z. Two or three
  // characters long. generated straight from github copilot
  // two 2/3 of the time and three 1/3 of the time
  const initialsLength = Math.random() < 0.33 ? 3 : 2;
  const initials = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    .replace(/[QXYZ]/g, "")
    .split("")
    .sort(() => Math.random() - 0.5)
    .slice(0, initialsLength)
    .join("");
  return initials;
}

function _makeGameData(sportsGame: ScheduledGame, size: 25 | 100): NewSquaresGameData {
  if (sportsGame.type === "Football") {
    return {
      type: "Squares",
      size,
      startTimestamp: sportsGame.startTimestamp,
      sportsGames: {
        type: "Football",
        games: {
          [sportsGame.id]: {
            ...sportsGame,
            status: "NS",
          },
        },
      },
    };
  } else {
    return {
      type: "Squares",
      size,
      startTimestamp: sportsGame.startTimestamp,
      sportsGames: {
        type: "Baseball",
        games: {
          [sportsGame.id]: {
            ...sportsGame,
            status: "NS",
          },
        },
      },
    };
  }
}

export default function NewSquaresGame({ sport }: { sport: "Football" | "Baseball" }) {
  const { specialId, teamSlate: teamSlateParam } = useParams() as { specialId?: string; teamSlate?: TeamSlate };

  if (teamSlateParam && !Object.values(TeamSlate).includes(teamSlateParam)) {
    throw new Error(`Invalid team slate: ${teamSlateParam}`);
  }

  const [teamSlate, setTeamSlate] = useState(teamSlateParam ?? TeamSlate.NFL);
  const [slateWeekGames, setSlateWeekGames] = useState<ScheduledFootballGame[] | undefined>();
  const [selectedGame, setSelectedGame] = useState<ScheduledGame | undefined>();
  const [size, setSize] = useState<25 | 100>(25);

  const [exampleData, setExampleData] = useState<StartedSquaresGameData | undefined>();

  // specials are loaded async so we smuggle any specialId through pendingJumpToSpecial
  // for a one time specials lookup once they are loaded.
  const [pendingJumpToSpecial, setPendingJumpToSpecial] = useState(specialId);

  const specials = useSpecials();

  // Handle setting up the special game if we have a specialId
  useEffect(() => {
    if (!pendingJumpToSpecial || sport !== "Football") {
      return;
    }
    if (selectedGame) {
      setPendingJumpToSpecial(undefined);
      return;
    }
    if (!specials) {
      return;
    }
    const special = specials[pendingJumpToSpecial];
    const now = Date.now();
    const specialGame = special?.find((game) => game.startTimestamp > now);
    if (specialGame) {
      setSelectedGame(specialGame);
      setSlateWeekGames([specialGame]);
    }
    setPendingJumpToSpecial(undefined);
  }, [specials, pendingJumpToSpecial, selectedGame, sport]);

  // change of pickedTeamSlate can invalidate selectedGame
  useEffect(() => {
    if (sport !== "Football") {
      return;
    }
    // see if we need to reset the selectedGame
    if (!slateWeekGames) {
      if (selectedGame) {
        setSelectedGame(undefined);
      }
      return;
    }
    const updatedGame = selectedGame ? slateWeekGames.find((game) => game.id === selectedGame.id) : slateWeekGames[0];
    if (!deepEqual(updatedGame, selectedGame)) {
      setSelectedGame(updatedGame);
    }
  }, [slateWeekGames, selectedGame, sport]);

  useEffect(() => {
    if (!selectedGame) {
      return;
    }
    const lockedEntries: Record<string, BasicEntryData> = {};
    for (let row = 0; row < Math.sqrt(size); row++) {
      for (let col = 0; col < Math.sqrt(size); col++) {
        const cell = getCellId({ row, col });
        lockedEntries[cell] = { uid: cell, entryName: _generateInitials() };
      }
    }
    const exampleGame: StartedSquaresGameData = {
      ..._makeGameData(selectedGame, size),
      targetScores: generateTargetScores(size),
      name: "",
      id: "example-game",
      uid: "",
      code: "",
      description: "",
      lockedEntries,
    };
    if (sport === "Football") {
      if (selectedGame.type !== "Football") {
        throw new Error("Selected game is not a football game");
      }
      exampleGame.sportsGames = {
        type: "Football",
        games: {
          [selectedGame.id]: {
            ...selectedGame,
            status: "Q4",
            periodScores: [
              { away: 0, home: 3 },
              { away: 3, home: 0 },
              { away: 10, home: 0 },
              { away: 22, home: 16 },
            ],
          },
        },
      };
    } else {
      if (selectedGame.type !== "Baseball") {
        throw new Error("Selected game is not a baseball game");
      }
      exampleGame.sportsGames = {
        type: "Baseball",
        games: {
          [selectedGame.id]: {
            ...selectedGame,
            status: "IN_PLAY",
            periodScores: [0, 3, 0, 1, 1],
          },
        },
      };
    }
    setExampleData(exampleGame);
  }, [selectedGame, size, sport]);

  function setFootballGameId(id: string) {
    const newGame = slateWeekGames?.find((game) => game.id === id);
    if (newGame) {
      setSelectedGame(newGame);
    }
  }

  const getCreateGameData = !selectedGame ? undefined : () => _makeGameData(selectedGame, size);

  function setTeamSlateFn(teamSlate: TeamSlate) {
    setTeamSlate(teamSlate);
    window.history.replaceState(null, "", `/games/new/football-squares/slate/${teamSlate}`);
  }

  const newGameStrings = {
    ...gameTypeStrings[sport],
    longName: sport === "Football" ? `${TeamSlateLongNames[teamSlate]} Squares` : `MLB Squares`,
  };

  return (
    <NewGame newGameStrings={newGameStrings} getCreateGameData={getCreateGameData}>
      <Stack spacing={0.5}>
        {/* Game Selection */}
        <FullSizeCard>
          <Stack spacing={3}>
            {sport === "Football" && (
              <SlateGamesPicker
                teamSlate={teamSlate ?? TeamSlate.NFL}
                title="Choose any NFL or NCAA game"
                description="Sportsball can run a squares game for any football game! Choose a game below."
                setTeamSlate={setTeamSlateFn}
                slateWeekGames={slateWeekGames ?? []}
                setSlateWeekGames={setSlateWeekGames}
              />
            )}
            {sport === "Baseball" && (!selectedGame || selectedGame.type === "Baseball") && (
              <MlbGamePicker selectedGame={selectedGame} setSelectedGame={setSelectedGame} />
            )}
            {selectedGame && slateWeekGames && (
              <TextField
                select
                fullWidth
                id="game"
                label={`${teamSlate} Game`}
                value={selectedGame?.id ?? ""}
                SelectProps={{
                  MenuProps: {
                    style: {
                      maxHeight: 400,
                    },
                  },
                }}
                onChange={(e) => setFootballGameId(e.target.value)}
              >
                {slateWeekGames.map(({ startTimestamp, teams, id }) => {
                  const date = format(startTimestamp, "EEE p");
                  const { away, home } = teams;
                  return (
                    <MenuItem key={id} value={id}>
                      {away.name}
                      {away.rank && `#${away.rank} `} @ {home.name}
                      {home.rank && `#${home.rank}`} - {date}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Stack>
        </FullSizeCard>

        {/* Board Size Selection */}
        <FullSizeCard>
          <Stack spacing={2}>
            <Typography variant="h5" component="h2">
              Choose the squares board size
            </Typography>
            <Typography>
              For a simpler game with more balanced odds, choose a 5x5 board. 5x5 boards assign two scores to each row
              and column. 10x10 boards are available for the classic squares experience.
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <ButtonGroup variant="contained" size="large">
                <Button
                  variant={size === 25 ? "contained" : "outlined"}
                  onClick={() => setSize(25)}
                  sx={{ px: 4, py: 1 }}
                >
                  5x5
                </Button>
                <Button
                  variant={size === 100 ? "contained" : "outlined"}
                  onClick={() => setSize(100)}
                  sx={{ px: 4, py: 1 }}
                >
                  10x10
                </Button>
              </ButtonGroup>
            </Box>
          </Stack>
        </FullSizeCard>

        {/* Preview */}
        <FullSizeCard>
          <Stack spacing={3}>
            <Typography variant="h5" component="h2">
              Game Preview
            </Typography>
            {selectedGame && exampleData && (
              <Box sx={{ mt: 2 }}>
                <SquaresBoard game={exampleData} />
              </Box>
            )}
          </Stack>
        </FullSizeCard>
      </Stack>
    </NewGame>
  );
}

import { useEffect, useState } from "react";

import { ServiceStatus } from "@sportsball/shared";
import { useFirebasePreUser } from "@/context/useFirebasePreUser";

function _serviceVersionsCompatible(a: string, b: string): boolean {
  const [aMajor, aMinor] = a.split(".");
  const [bMajor, bMinor] = b.split(".");
  return aMajor === bMajor && aMinor === bMinor;
}

export function useServiceVersion() {
  const firebase = useFirebasePreUser();

  const [initialServiceVersion, setServiceVersion] = useState<string | undefined>();

  useEffect(() => {
    if (!firebase) {
      return;
    }

    const { onValue, ref } = firebase.databasePackage;
    const serviceStatusRef = ref(firebase.database, "status/service");
    return onValue(serviceStatusRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }
      const { version: reportedVersion } = ServiceStatus.parse(snapshot.val());
      if (!reportedVersion) {
        // shrug
        return;
      }
      if (!initialServiceVersion) {
        console.log("Starting up with service version:", reportedVersion);
        setServiceVersion(reportedVersion);
      } else if (!_serviceVersionsCompatible(initialServiceVersion, reportedVersion)) {
        console.log(`Reloading to sync with service version: ${reportedVersion} -> ${initialServiceVersion}`);
        window.location.reload();
      }
      // otherwise we have a spurious reminder of the service version that we ignore.
    });
  }, [firebase, initialServiceVersion, setServiceVersion]);

  return initialServiceVersion;
}

import { z } from "zod";

// React -> Flutter
export const OutgoingCreateGameSuccessPayload = z.object({
  status: z.literal("success"),
  gameId: z.string(),
});
export type OutgoingCreateGameSuccessPayload = z.infer<typeof OutgoingCreateGameSuccessPayload>;

export const OutgoingCreateGameErrorPayload = z.object({
  status: z.literal("error"),
  message: z.string(),
});
export type OutgoingCreateGameErrorPayload = z.infer<typeof OutgoingCreateGameErrorPayload>;

export const OutgoingCreateGamePayload = z.discriminatedUnion("status", [
  OutgoingCreateGameSuccessPayload,
  OutgoingCreateGameErrorPayload,
]);
export type OutgoingCreateGamePayload = z.infer<typeof OutgoingCreateGamePayload>;

export function sendNewGameMessage(payload: OutgoingCreateGamePayload) {
  if (!window.Flutter) {
    console.error("No window.Flutter");
    return;
  }
  try {
    window.Flutter.postMessage(
      JSON.stringify({
        action: "createGame",
        payload,
      })
    );
  } catch (error: unknown) {
    console.error("Error sending message to Flutter:", error instanceof Error ? error.message : String(error));
  }
}

// Flutter -> React
export const IncomingCreateGameMessage = z.object({
  action: z.literal("createGame"),
});
export type IncomingCreateGameMessage = z.infer<typeof IncomingCreateGameMessage>;

window.receiveNewGameMessage = (messageJson: string) => {
  if (!window.receiveNewGameMessageImpl) {
    console.error("No window.receiveNewGameMessageImpl");
    return;
  }
  try {
    console.log("Received message", JSON.parse(messageJson));
    const message = IncomingCreateGameMessage.parse(JSON.parse(messageJson));
    window.receiveNewGameMessageImpl?.(message);
  } catch (error) {
    console.log("Error processing message from Flutter", error);
    alert(`React: Error processing message from Flutter: ${String(error)}`);
  }
};

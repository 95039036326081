import { createTheme } from "@mui/material/styles";

// You can extract these into a separate colors.ts file if you want
const colors = {
  sportBlue: {
    main: "#1e4784", // Professional, trustworthy blue
    light: "#4169a9",
    dark: "#0d2b5c",
  },
  sportRed: {
    main: "#d32f2f", // Energetic, exciting red
    light: "#ef5350",
    dark: "#c62828",
  },
  fieldGreen: {
    main: "#2e7d32", // Success, positive green
    light: "#4caf50",
    dark: "#1b5e20",
  },
  gameStates: {
    live: "#00c853", // Active games
    upcoming: "#fb8c00", // Future games
    final: "#546e7a", // Completed games
  },
};

const theme = createTheme({
  palette: {
    primary: colors.sportBlue,
    secondary: colors.sportRed,
    success: colors.fieldGreen,
    background: {
      default: "#f5f7fa", // Light grey-blue background
      paper: "#ffffff",
    },
    text: {
      primary: "#2c3e50", // Dark blue-grey text
      secondary: "#546e7a",
    },
  },
  typography: {
    fontFamily: [
      "Poppins", // Keep your existing Poppins
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontSize: "2.5rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    button: {
      textTransform: "none", // Prevents all-caps buttons
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: "8px 16px",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
          "&:hover": {
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
      variants: [
        {
          props: { component: "nav" },
          style: {
            borderRadius: 0, // Remove border radius for AppBar
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
  },
});

export default theme;

import { Fragment } from "react/jsx-runtime";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ScheduledGame } from "@sportsball/shared";

import { useSpecials } from "@/context/useSpecials";
import { useLeagueWeeks } from "@/hooks/leagueWeeksHook";

export default function NewGameBoard() {
  const leagueWeeks = useLeagueWeeks();
  const specials = useSpecials();

  const currentNflLeagueWeek = leagueWeeks?.NFL?.week;

  const nowTimestamp = Date.now();
  const bestSpecials: [string, ScheduledGame][] = [];
  for (const [key, specialOptions] of Object.entries(specials ?? {})) {
    for (const special of specialOptions ?? []) {
      if (special.startTimestamp > nowTimestamp) {
        bestSpecials.push([key, special]);
        break;
      }
    }
  }
  bestSpecials.sort(([, a], [, b]) => a.startTimestamp - b.startTimestamp);
  return (
    <Fragment>
      <Box sx={{ border: 1, borderRadius: 1, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Premier Squares Games
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-evenly">
          {bestSpecials.map(([specialId, special]) => (
            <Box key={specialId} marginBottom={1}>
              <Link to={`/games/new/football-squares/special/${specialId}`}>
                <Button variant="contained" fullWidth={true}>
                  <Stack>
                    <Typography>{specialId} Squares</Typography>
                    {special.teams.away.name !== "Away" && (
                      <Typography>
                        {special.teams.away.name} @ {special.teams.home.name}
                      </Typography>
                    )}
                  </Stack>
                </Button>
              </Link>
            </Box>
          ))}
        </Stack>
      </Box>
      {currentNflLeagueWeek && (
        <Box sx={{ border: 1, borderRadius: 1, p: 2 }}>
          <Typography variant="h6" gutterBottom>
            NFL Pools
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-evenly">
            <Box marginBottom={1}>
              <Link to={`/games/new/football-squares/slate/NFL`}>
                <Button variant="contained" fullWidth={true}>
                  <Stack>
                    <Typography>NFL {currentNflLeagueWeek} Squares</Typography>
                  </Stack>
                </Button>
              </Link>
            </Box>
            <Box marginBottom={1}>
              <Link to={`/games/new/football-pickem/slate/NFL`}>
                <Button variant="contained" fullWidth={true}>
                  <Stack>
                    <Typography>NFL {currentNflLeagueWeek} Pick&apos;em Pool</Typography>
                  </Stack>
                </Button>
              </Link>
            </Box>
          </Stack>
        </Box>
      )}
    </Fragment>
  );
}

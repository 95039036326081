import { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import { SomeGameData } from "@sportsball/shared";

import FullSizeCard from "@/components/FullSizeCard";
import GameCard from "@/components/GameCard";
import NewGameBoard from "@/components/NewGameBoard";

import { LazyFirebase } from "@/context/useFirebasePreUser";
import { maybeUserUid, useUser } from "@/context/useUser";
import { splitUpGames, useUserGames } from "@/hooks/userGamesHook";

export function searchByCode(
  code: string | undefined,
  setGame: (game: SomeGameData | undefined) => void,
  firebase: LazyFirebase | undefined
) {
  if (!code || !firebase) {
    setGame(undefined);
    return;
  }
  const { collection, onSnapshot, query, where } = firebase.firestorePackage;
  const gamesCollection = collection(firebase.firestore, "games");
  const q = query(gamesCollection, where("code", "==", code.toUpperCase()));
  return onSnapshot(q, (snapshot) => {
    if (snapshot.empty) {
      setGame(undefined);
    } else {
      snapshot.forEach((gameSnapshot) => {
        const result = SomeGameData.safeParse(gameSnapshot.data());
        if (!result.success) {
          setGame(undefined);
          return;
        }
        setGame(result.data);
      });
    }
  });
}

export default function Home() {
  const { firebase, user } = useUser();
  const [searchGameCode, setSearchGameCode] = useState<string | undefined>();
  const [searchGame, setSearchGame] = useState<SomeGameData | undefined>();

  useEffect(() => searchByCode(searchGameCode, setSearchGame, firebase), [searchGameCode, firebase]);

  const uid = maybeUserUid(user);
  const games = useUserGames();
  const { hosting, enteredNotHosting } =
    !uid || !games ? { hosting: [], enteredNotHosting: [] } : splitUpGames(games, { uid });

  return (
    <Stack spacing={0.5}>
      {/* Hero Section */}
      <FullSizeCard>
        <Box
          sx={{
            textAlign: "center",
            py: 4,
          }}
        >
          <Typography variant="h1" gutterBottom>
            Football pools with friends
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ maxWidth: "800px", mx: "auto", mb: 4 }}>
            Setting up squares or pick&apos;em pools for NFL and MLB games is quick and easy on Sportsball, right from
            your phone.
          </Typography>
        </Box>
      </FullSizeCard>

      {/* Active Games Section */}
      {(hosting.length > 0 || enteredNotHosting.length > 0) && (
        <FullSizeCard>
          <Stack spacing={4}>
            {hosting.length > 0 && (
              <Box>
                <Typography variant="h2" gutterBottom>
                  Games you are running
                </Typography>
                <Stack spacing={2}>
                  {hosting.map((game, i) => (
                    <GameCard key={i} game={game} />
                  ))}
                </Stack>
              </Box>
            )}

            {enteredNotHosting.length > 0 && (
              <Box>
                <Typography variant="h2" gutterBottom>
                  Games you are playing
                </Typography>
                <Stack spacing={2}>
                  {enteredNotHosting.map((game, i) => (
                    <GameCard key={i} game={game} />
                  ))}
                </Stack>
              </Box>
            )}
          </Stack>
        </FullSizeCard>
      )}

      {/* Create Game Section */}
      <FullSizeCard>
        <Typography variant="h2" gutterBottom>
          Create your own new game
        </Typography>
        <Typography variant="body1" gutterBottom>
          Ready to start your own game? It&apos;s easy! Choose which type of game you wish to run:
        </Typography>
        <Box sx={{ mt: 3 }}>
          <NewGameBoard />
        </Box>
      </FullSizeCard>

      {/* Join Game Section */}
      <FullSizeCard>
        <Typography variant="h2" gutterBottom>
          Join by game code
        </Typography>
        <Stack spacing={3}>
          <Typography variant="body1">
            Each game has a short code that allows others to join. Enter the game code here.
          </Typography>

          <TextField
            fullWidth
            id="searchGameCode"
            label="Enter Game Code"
            variant="outlined"
            sx={{ maxWidth: 300 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: { textTransform: "uppercase" },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }}
            onChange={(e) => setSearchGameCode(e.target.value.toUpperCase())}
          />

          {searchGame && (
            <Box sx={{ mt: 2 }}>
              <GameCard game={searchGame} />
            </Box>
          )}
        </Stack>
      </FullSizeCard>
    </Stack>
  );
}

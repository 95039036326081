import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { GameContainerProps } from "@/components/GameContainer";
import { useOutletContext } from "react-router-dom";

import QuestionsGame from "./QuestionsGame";
import SquaresGame from "./squares/SquaresGame";
import { useUser } from "@/context/useUser";

export default function WebViewGame() {
  const { firebase } = useUser();
  const { type, game, gameId, gameOn, pregameEntries } = useOutletContext<GameContainerProps>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!firebase) {
      return;
    }
    const token = searchParams.get("token");
    if (token) {
      const { signInWithCustomToken } = firebase.authPackage;
      // Sign in with the token received from mobile app
      signInWithCustomToken(firebase.auth, token).catch(alert);
    }
  }, [searchParams, firebase]);

  switch (type) {
    case "Pickem":
      return <QuestionsGame gameId={gameId} game={game} gameOn={gameOn} pregameEntries={pregameEntries} />;

    case "Squares":
      return <SquaresGame gameId={gameId} game={game} pregameEntries={pregameEntries} />;
  }
}

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { RoomData, SomeGameData } from "@sportsball/shared";

import GameCard from "@/components/GameCard";

import { useLoggedInFirestoreObject } from "@/hooks/loggedInObjectHook";
import { maybeUserUid, useUser } from "@/context/useUser";
import { splitUpGames } from "@/hooks/userGamesHook";

export default function Room() {
  const { roomId } = useParams();
  const { user, firebase } = useUser();
  const [roomGames, setRoomGames] = useState<SomeGameData[] | undefined>();
  const uid = maybeUserUid(user);
  const { object: room, loading, error } = useLoggedInFirestoreObject(`rooms/${roomId}`, RoomData);

  // update roomGames
  useEffect(() => {
    if (!firebase) {
      return;
    }
    const { collection, onSnapshot, query, where } = firebase.firestorePackage;
    const gamesCollection = collection(firebase.firestore, "games");
    const q = query(gamesCollection, where("inRooms", "array-contains", roomId));
    return onSnapshot(q, (snapshot) => {
      const games: SomeGameData[] = [];
      snapshot.forEach((doc) => {
        games.push(SomeGameData.parse(doc.data()));
      });
      setRoomGames(games);
    });
  }, [firebase, roomId]);

  if (!roomId) {
    return <div>Room not found</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (!room) {
    return <div>Room not found</div>;
  }

  const hostingGames = !uid || !roomGames ? [] : splitUpGames(roomGames, { uid }).hosting;
  const roomlessGames = hostingGames.filter((game) => !game.inRooms);

  function addGameToRoom(gameId: string) {
    if (!firebase || !uid || !roomId) {
      return;
    }
    const { arrayUnion, doc, collection, updateDoc } = firebase.firestorePackage;
    const gameDoc = doc(collection(firebase.firestore, "games"), gameId);
    updateDoc(gameDoc, { inRooms: arrayUnion(roomId) }).catch(alert);
  }

  function removeGameFromRoom(gameId: string) {
    if (!firebase) {
      return;
    }
    const { arrayRemove, doc, collection, updateDoc } = firebase.firestorePackage;
    const gameDoc = doc(collection(firebase.firestore, "games"), gameId);
    updateDoc(gameDoc, { inRooms: arrayRemove(roomId) }).catch(alert);
  }

  return (
    <>
      <h1>Room {roomId}</h1>
      <Stack spacing={2}>
        <h2>Games in this room</h2>
        {roomGames?.map((game, i) => (
          <Stack key={i} direction="row" spacing={2}>
            {uid === room.uid && (
              <Button onClick={() => removeGameFromRoom(game.id)}>
                <RemoveCircleIcon />
              </Button>
            )}
            <GameCard key={i} game={game} />
          </Stack>
        ))}
      </Stack>
      ;
      {uid === room.uid && roomlessGames.length > 0 && (
        <>
          <h2>Your games not in any room</h2>
          <Stack spacing={2}>
            {roomlessGames.map((game, i) => (
              <Stack key={i} direction="row" spacing={2}>
                <Button onClick={() => addGameToRoom(game.id)}>
                  <AddCircleIcon />
                </Button>
                <GameCard key={i} game={game} />
              </Stack>
            ))}
          </Stack>
        </>
      )}
    </>
  );
}
